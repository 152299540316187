<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <h4 class="f-bold pt-2 pl-3 cl-primary text-left">Customer Packages</h4>
      </div>
      <div class="col-12 col-md-6">
        <nav aria-label="breadcrumb">
          <ol
            class="breadcrumb float-right"
            style="background-color: transparent"
          >
            <li class="breadcrumb-item">
              <router-link to="/warehouse/dashboard">Dashboard</router-link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Customer Packages
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="table-responsive">
      <div class="d-flex justify-content-around" v-if="loading">
        <loader-component></loader-component>
      </div>
      <table class="table table-bordered table-hover" v-if="!loading">
        <thead class="thead-light">
          <tr>
            <th colspan="2"></th>
            <th scope="col" colspan="5">Packages Customer</th>
          </tr>
        </thead>
        <thead class="thead-light">
          <tr>
            <th scope="col">No</th>
            <th scope="col">Customer Name</th>
            <th scope="col">Tracking Number</th>
            <th scope="col">Code</th>
            <th scope="col">Date</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="data.length == 0">
            <td colspan="7">Packages is empty</td>
          </tr>
          <tr v-for="(packages, p) in data" :key="p">
            <th scope="row">{{ p + 1 }}</th>
            <td>{{ packages.customer_detail.name }}</td>
            <td>
              {{
                packages.package_detail.tracking_number
                  ? packages.package_detail.tracking_number
                  : "not submitted"
              }}
            </td>
            <td class="cl-primary f-bold">{{ packages.code }}</td>
            <td>{{ moment(packages.date_submission).format("DD/MM/YYYY") }}</td>
            <td>
              <div class="d-flex justify-content-around">
                <router-link
                  :to="
                    '/warehouse/customer-packages/detail/' +
                    packages.code +
                    '/' +
                    packages.id
                  "
                  ><span class="material-icons"> launch </span></router-link
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import LoaderComponent from "../../components/Loader.vue";
export default {
  name: "CustomerPackages",
  components: { LoaderComponent },
  data() {
    return {
      loading: true,
      data: [],
    };
  },
  mounted() {
    this.getPackages();
  },

  methods: {
    getPackages() {
      const endpoint = "customer-package/warehouse";
      this.$api.get(endpoint, (status, data, message) => {
        if (status === 200) {
          this.loading = false;
          this.data = data.customer_package;
          console.log(this.data);
        } else {
          this.loading = false;
          console.log(message);
        }
      });
    },
  },
};
</script>
